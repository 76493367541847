import React, { useEffect, useState } from 'react';
import './VideoModal.css';

const VideoModal = ({ videoUrl, isOpen, handleClose }) => {
    useEffect(() => {
        const videoElement = document.getElementById('videoPlayer');

        if (videoElement) {
            videoElement.play();
        }
    }, [isOpen]);

    return (
        <div>
            {/* <button onClick={openModal}>Open Modal</button> */}

            {isOpen && (
                <div className="modal">
                    <div className="modal-content">
                        <span className="close" onClick={handleClose}>&times;</span>
                        <video id='videoPlayer' width="100%" height="100%" controls>
                            <source src={videoUrl} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </div>
                </div>
            )}
        </div>
    );
};

export default VideoModal;
