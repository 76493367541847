import React from 'react';


import "./privacyPolicy.css";
const PrivacyPolicy = () => {
  return (
    <div className='privacy-policy-class'>


      <h1 className='text-center' style={{ textAlign: "center" }}>Privacy Policy</h1>
      <p>
        Welcome to www.icronservices.com. This Privacy Policy outlines how we collect, use, disclose, and safeguard the personal information obtained from users of our website.</p>
      <h3>Information Collection and Use</h3>
      <p>We only collect personal information voluntarily provided by users through our Contact Us form and mailto functionality. This may include:
      </p>
      <ol>
        <li>
          Name: Used to address the user and respond to inquiries.
        </li>
        <li>

          Email Address: Used to respond to inquiries and provide relevant information.
        </li>
        <li>
          Message/Inquiry: Information shared by the user to facilitate communication and address inquiries.

        </li>
      </ol>
      <p>
      </p>
      <h3>
        Use of Information
      </h3>
      <p>
        The information collected is used for the following purposes:
      </p>
      <ol>
        <li>Responding to inquiries and providing the requested information.</li>
        <li>Improving our services and understanding user needs.</li>
        <li>Communicating with users about our services and updates.</li>
      </ol>
      <h3>
        Sharing of Information

      </h3>
      <p>

        We do not sell, trade, or otherwise transfer personally identifiable information to third parties. However, we may share your information with trusted service providers who assist us in operating our website and conducting our business, provided that these parties agree to keep this information confidential.
      </p>
      <h3>
        Security
      </h3>
      <p>
        We implement appropriate security measures to protect against unauthorized access, alteration, disclosure, or destruction of your personal information.
      </p>
      <h3>
        Links to Other Websites
      </h3>
      <p>
        Our website may contain links to external sites not operated by us. We have no control over the content and practices of these sites and encourage you to review their respective privacy policies.
      </p>
      <h3>
        Changes to This Privacy Policy
      </h3>
      <p>
        We reserve the right to update or change this Privacy Policy at any time.
      </p>

    </div>
  )
}

export default PrivacyPolicy;