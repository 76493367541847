import React from 'react'

import PropTypes from 'prop-types'

import Tag from './tag'
import './work.css'
import VideoModal from './VIdeoModal'
import Link from './link'

const Work = (props) => {
  return (
    <div className="work-work">
      <img alt="image" src={props.Image} className="work-image" />
      <div className="work-details">
        <div className="work-header">
          <div className="work-heading">
            <span className="work-text">{props.Title}</span>
          </div>
          <span className="work-text1">{props.Description}</span>
        </div>
        <Link Caption="Check it out" hrefLink={props.goToLink} clickOnLink={props.openVideoModal}></Link>
        {/* {}
        <div>
          <VideoModal videoUrl={'/transition-template.mp4'} />
        </div> */}

        <div className="work-tags">
          {/* <Tag> */}

          {/* </Tag> */}
        </div>
      </div>
    </div>
  )
}

// Work.defaultProps = {
//   Image: '/work%20%231-1500w.png',
//   Description:
//     'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.',
//   Title: 'MOTECH',
// }

Work.propTypes = {
  Image: PropTypes.string,
  Description: PropTypes.string,
  Title: PropTypes.string,
}

export default Work
