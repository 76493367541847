import React, { useState } from 'react'
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet'

import Service from '../components/service'
import Statistic from '../components/statistic'
import Link from '../components/link'
import Work from '../components/work'
import './home.css'
import VideoModal from '../components/VIdeoModal';

const Home = () => {

  const history = useHistory();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [disableButton, setDisableButton] = useState(false);
  const [classChange, setClassChange] = useState(false);
  const [className, setClassName] = useState('home-mobile-menu');

  const [modalOpen, setModalOpen] = useState(false);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };


  const handleSubmit = async (event) => {
    event.preventDefault();
    setDisableButton(true);
    await fetch('https://www.icronservices.com', {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        name,
        email,
        message,
      }),
    })
      .then(() => {
        window.location.reload();
        setDisableButton(false);
      })
      .catch((err) => {
        console.log(err);
        setDisableButton(false);
      });
  };

  const handleClass = async () => {
    setClassName("home-mobile-menu teleport-show thq-show thq-translate-to-default");
  }

  const handleClass2 = async () => {
    setClassName("home-mobile-menu");

  }

  return (
    <div className="home-container">
      {<div>
        <VideoModal videoUrl={'/transition-template.mp4'} handleClose={closeModal} isOpen={modalOpen} />
      </div>}
      <Helmet>
        <title>iCronServices - Seamless IT Solutions at Your Fingertips</title>
        <meta property="og:title" content="Agency modern template1" />
      </Helmet>
      <div className="home-navbar">
        <header data-thq="thq-navbar" className="home-navbar-interactive">
          <div className="home-branding">
            <img
              src="/external/logo-white-cropped-200h.png"
              alt="image"
              className="home-image"
              onClick={() => history.push("/")}
            />
          </div>
          <div className="home-items">
            <div className="home-links">
              <span className="nav-link"><a href='#about'>About</a></span>
              <span className="nav-link"><a href='#work'>Work</a></span>
              <span className="nav-link"><a href='#services'>Services</a></span>
              <span className="nav-link"><a href='#join-us'>Join us</a></span>
              <span className="nav-link"><a href='#contact-us'>Contact</a></span>
            </div>
          </div>
          <div className="home-burger-menu" onClick={() => {
            handleClass();
          }}>
            <button className="home-button button">
              <img alt="image" src="/hamburger.svg" className="home-image1" />
              <span className="home-text04">Start a project</span>
            </button>
          </div>
          <div className={className}>
            <div

              data-role="Nav"
              className="home-nav"
            >
              <div className="home-top">
                <div className="home-menu-close" onClick={() => {
                  handleClass2();
                }}>
                  <svg viewBox="0 0 1024 1024" className="home-icon">
                    <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                  </svg>
                </div>
              </div>
              <div className="home-items1">
                <div className="home-links1">
                  <span className="nav-link" onClick={() => {
                    handleClass2();
                  }}><a href='#about'>About</a></span>
                  <span className="nav-link" onClick={() => {
                    handleClass2();
                  }}><a href='#work'>Work</a></span>
                  <span className="nav-link" onClick={() => {
                    handleClass2();
                  }}><a href='#services'>Services</a></span>
                  <span className="nav-link" onClick={() => {
                    handleClass2();
                  }}><a href='#join-us'>Join us</a></span>
                  <span className="nav-link" onClick={() => {
                    handleClass2();
                  }}><a href='#contact-us'>Contact</a></span>
                </div>
              </div>
            </div>
          </div>
        </header>
      </div>
      <header className="home-hero">
        <div className="home-header">
          <h1 className="home-title">Your Tech Odyssey Starts Here</h1>
          <p className="home-description">
            Engineered for Excellence: Providing Tech Services Aligned with Your
            Unique Goals and Vision.
          </p>
        </div>
      </header>
      <div className="home-description1" id='about'>
        <div className="home-content">
          <div className="home-text09" >
            <p className="home-paragraph">
              We are a cohesive team providing expert tech services to optimize
              digital experiences.
            </p>
            <p className="home-paragraph1">
              Our collaborative approach merges technical prowess and creative
              ingenuity, crafting seamless solutions for a tech-forward world.
            </p>
          </div>
        </div>
      </div>
      {/* add featured work here */}
      <div className="home-featured">
        <div className="home-header" style={{ "paddingBottom": "59px" }} id='work'>
          <h2 className="heading">Featured Work</h2>
          <div className="home-link">
            {/* <Link
              Caption="100+ Projects"
              rootClassName="link-root-class-name"
            ></Link> */}
          </div>
        </div>
        <div className="home-list">
          {/* <Work></Work> */}
          <Work Image="/QjKeJzpLWchJLRoZJY.png" Title="CHAT APP" Description={"A cutting-edge, real-time chat application designed to revolutionize the way you communicate with others. Harnessing the power of the MERN stack—MongoDB, Express.js, React, and Node.js—It offers a seamless and interactive platform for users to engage in both individual and group conversations."}
            goToLink="https://chat-app-1dfe6.web.app/"></Work>
          {/* <Link
              Caption="100+ Projects"
              rootClassName="link-root-class-name"
            ></Link> */}
          <Work Image="/dxpHVNFrFAzJ.gif" Title="TRANSITION VIDEO" Description={"Enjoy a brief, visually striking transition video complemented by a vibrant audio soundtrack. Watch as scenes seamlessly evolve, harmonizing with captivating sound, creating an engaging and immersive viewing experience."}
            href="#" openVideoModal={openModal}></Work>
          <Work Image="/DKSNZWuHkans.png" Title="ADD PLACES APP" Description={"Add Places App lets users save and map their favorite locations effortlessly. Log and locate cherished spots on a map, making planning and revisiting easy and enjoyable. Your personalized map awaits!"} goToLink="https://like-places.web.app/"></Work>
        </div>
      </div>
      <div className="home-services" >
        <div className="home-header1" id='services'>
          <h2 className="heading">SERVICES</h2>
        </div>
        <div className="home-grid">
          <Service
            Title="UI / UX"
            Description="Intuitive designs for engaging user experiences."
            rootClassName="service-root-class-name"
          ></Service>
          <Service
            Title="Web Development"
            Description="Seamless web solutions to make you stand out."
            rootClassName="service-root-class-name1"
          ></Service>
          <Service
            Title="Graphic Designing"
            Description="Unlock your brand's potential through captivating and strategic graphic design."
            rootClassName="service-root-class-name5"
          ></Service>
          <Service
            Title="Video Editing"
            Description="Craft compelling stories through expert video editing."
            rootClassName="service-root-class-name2"
          ></Service>
          <Service
            Title="Project Management"
            Description="Efficiently drive projects from concept to successful completion."
            rootClassName="service-root-class-name3"
          ></Service>
          <Service
            Title="App Development"
            Description="Simplify lives with our innovative app solutions."
            rootClassName="service-root-class-name4"
          ></Service>
        </div>
      </div>
      <div className="home-about">
        <div className="home-statistics" id='join-us'>
          <button className="start-button button home-button1">
            <span className="home-text10">
              <a href="mailto:team@icronservices.com?subject=Regarding Joining Your Team">Join our team</a>
            </span>
          </button>
          <div className="home-statistic-list">
            <Statistic rootClassName="statistic-root-class-name3"></Statistic>
            <Statistic
              Value="60"
              Caption="Diversify opportunities, boost income"
              rootClassName="statistic-root-class-name"
            ></Statistic>
            <Statistic
              Value="15K"
              Caption="Flexible hours, your choice"
              rootClassName="statistic-root-class-name4"
            ></Statistic>
            <Statistic
              Value="70+"
              Caption="Fair pay, on time"
              rootClassName="statistic-root-class-name1"
            ></Statistic>
            <Statistic
              Value="300+"
              Caption="Grow skills, with support"
              rootClassName="statistic-root-class-name5"
            ></Statistic>
            <Statistic
              Value="1M"
              Caption="Effortless project management"
              rootClassName="statistic-root-class-name2"
            ></Statistic>
          </div>
        </div>

      </div>
      <div className="home-container1">
        <div className="home-information">
          <div className="home-details">
            <div className="home-container2">
              <img
                src="/external/logo-black-cropped-200h.png"
                alt="image"
                className="home-image2"
              />
            </div>
            <div className="home-location">
              <span className="home-value">team@icronservices.com</span>
              <span className="home-value1">+91-8882991214</span>
            </div>
          </div>
          <div>
            <h5 style={{ fontSize: "1.2rem", marginBottom: "1rem" }}>Follow us on social media</h5>
            <span style={{ marginRight: "1.5rem" }}>
              <a href='https://www.facebook.com/profile.php?id=61552158336756&mibextid=ZbWKwL'>
                <svg xmlns="http://www.w3.org/2000/svg" height="2rem" viewBox="0 0 448 512"><path d="M400 32H48A48 48 0 0 0 0 80v352a48 48 0 0 0 48 48h137.25V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.27c-30.81 0-40.42 19.12-40.42 38.73V256h68.78l-11 71.69h-57.78V480H400a48 48 0 0 0 48-48V80a48 48 0 0 0-48-48z" /></svg>
              </a>
            </span>
            <span>
              <a href='https://www.instagram.com/icronservices'>
                <svg xmlns="http://www.w3.org/2000/svg" height="2rem" viewBox="0 0 448 512">
                  <path d="M224,202.66A53.34,53.34,0,1,0,277.36,256,53.38,53.38,0,0,0,224,202.66Zm124.71-41a54,54,0,0,0-30.41-30.41c-21-8.29-71-6.43-94.3-6.43s-73.25-1.93-94.31,6.43a54,54,0,0,0-30.41,30.41c-8.28,21-6.43,71.05-6.43,94.33S91,329.26,99.32,350.33a54,54,0,0,0,30.41,30.41c21,8.29,71,6.43,94.31,6.43s73.24,1.93,94.3-6.43a54,54,0,0,0,30.41-30.41c8.35-21,6.43-71.05,6.43-94.33S357.1,182.74,348.75,161.67ZM224,338a82,82,0,1,1,82-82A81.9,81.9,0,0,1,224,338Zm85.38-148.3a19.14,19.14,0,1,1,19.13-19.14A19.1,19.1,0,0,1,309.42,189.74ZM400,32H48A48,48,0,0,0,0,80V432a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V80A48,48,0,0,0,400,32ZM382.88,322c-1.29,25.63-7.14,48.34-25.85,67s-41.4,24.63-67,25.85c-26.41,1.49-105.59,1.49-132,0-25.63-1.29-48.26-7.15-67-25.85s-24.63-41.42-25.85-67c-1.49-26.42-1.49-105.61,0-132,1.29-25.63,7.07-48.34,25.85-67s41.47-24.56,67-25.78c26.41-1.49,105.59-1.49,132,0,25.63,1.29,48.33,7.15,67,25.85s24.63,41.42,25.85,67.05C384.37,216.44,384.37,295.56,382.88,322Z" /></svg>
              </a>
            </span>
          </div>

        </div>
        <div className="home-information1" id='contact-us'>
          <span className="home-company">CONTACT US</span>
          <form
            className="home-form"
            onSubmit={handleSubmit}
          >
            <span className="home-text11">Full Name</span>
            <input
              type="text"
              required
              placeholder='Smith Joshua'
              autoComplete="off"
              className="home-textinput input"
              onChange={(e) => setName(e.target.value)}
            />
            <span className="home-text12">Email</span>
            <input
              type="email"
              required
              placeholder='smith@mail.com'
              autoComplete="off"
              className="home-textinput1 input"
              onChange={(e) => setEmail(e.target.value)}
            />
            <span className="home-text13">Message</span>
            <textarea
              autoComplete="off"
              className="home-textarea textarea"
              placeholder='Type here...'
              onChange={(e) => setMessage(e.target.value)}
            ></textarea>
            <button className="home-button2 start-button button" disabled={disableButton} style={{ opacity: disableButton && "0.5" }}>
              <span className="home-text14">Submit</span>
            </button>
          </form>
        </div>
      </div>
      <footer className="home-footer">

        <a href='/privacyPolicy'>
          <span style={{ marginRight: "1.15rem" }}>Privacy Policy</span>
        </a>
        <a href='/terms'>
          <span style={{ marginRight: "1.15rem" }}>Terms</span>
        </a>
        Copyright © 2023 icronservices</footer>

    </div>
  )
}

export default Home
