import React from 'react'

import PropTypes from 'prop-types'

import './statistic.css'

const Statistic = (props) => {
  return (
    <div className={`statistic-statistic ${props.rootClassName} `}>
      <span className="statistic-sta">{props.Caption}</span>
    </div>
  )
}

Statistic.defaultProps = {
  rootClassName: '',
  Value: '12',
  Caption: 'Steady projects, diverse clients',
}

Statistic.propTypes = {
  rootClassName: PropTypes.string,
  Value: PropTypes.string,
  Caption: PropTypes.string,
}

export default Statistic
