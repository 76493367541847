import React from 'react';

import "./terms.css";
const Terms = () => {
    return (
        <div className='website-terms'>
            <h1  style={{textAlign:"center"}}>Terms And Conditions</h1>
            <p>These terms and conditions govern your use of the website www.icronservices.com. By accessing and using the Website, you agree to abide by these Terms. If you do not agree to these Terms, please refrain from using the Website.</p>
            <h3>User Obligations</h3>
            <ol>
                <li>
                    Compliance: You agree to comply with all applicable laws and regulations while using the Website.
                </li>
                <li>
                    Lawful Use: You shall use the Website for lawful purposes only and not for any illegal, unethical, or unauthorized activities.
                </li>
            </ol>
            <h3>Privacy and Data Collection</h3>
            <p>
                Please refer to our Privacy Policy for information about how we collect, use, and protect your personal information.
            </p>
            <h3>Limitation of Liability</h3>
            <ol>
                <li>
                    No Warranty: The Website is provided on an "as-is" basis without any warranty, express or implied, including but not limited to warranties of merchantability, fitness for a particular purpose, and non-infringement.
                </li>
                <li>
                    Limitation of Liability: In no event shall www.icronservices.com be liable for any direct, indirect, incidental, consequential, or special damages arising out of or in connection with your use or inability to use the Website.
                </li>
            </ol>
            <h3>Modification of Terms</h3>
            <p>
                www.icronservices.com reserves the right to modify these Terms at any time without prior notice. Changes will be effective immediately upon posting on the Website. Your continued use of the Website signifies your acceptance of the modified Terms.
            </p>
        </div>
    )
}

export default Terms;