import React from 'react'
import ReactDOM from 'react-dom'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom'

import './style.css'
import Home from './views/home'
import NotFound from './views/not-found'
import PrivacyPolicy from './components/privacyPolicy'
import Terms from './components/terms'

const App = () => {
  return (
    <Router>
      <Switch>
        <Route component={Home} exact path="/" />
        {/* <Route component={NotFound} path="**" /> */}
        <Route path="/privacyPolicy" component={PrivacyPolicy} />
        <Route path="/terms" component={Terms} />
        <Redirect to="**" />
      </Switch>
    </Router>
  )
}

if (process.env.NODE_ENV === 'development') {
  const RefreshRuntime = require('react-refresh/runtime');
  RefreshRuntime.injectIntoGlobalHook(window);
  window['$RefreshReg$'] = () => { };
  window['$RefreshSig$'] = () => (type) => type;
}

ReactDOM.render(<App />, document.getElementById('app'))
